var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.event !== null
      ? _c("div", { attrs: { id: "availability-block" } }, [
          _c("div", { staticClass: "container center-align padding-x-xl" }, [
            _c(
              "h1",
              {
                staticClass: "title strong margin-top-none",
                staticStyle: { "margin-top": "23px" }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$translate(
                        "availability.availability_date_blocked_title",
                        {
                          meeting_start_at: _vm.event._meeting_start_at.format(
                            "MMMM DD"
                          )
                        }
                      )
                    ) +
                    "\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _c("small", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$translate(
                      "availability.availability_date_blocked_description",
                      {
                        meeting_start_at: _vm.event._meeting_start_at.format(
                          "MMMM DD"
                        )
                      }
                    )
                  )
                }
              })
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "availability-img responsive-img",
              attrs: { src: "/img/events/availability.png", alt: "" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "navigator row margin-top-lg" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-full btn-large btn-krypton-pink waves-effect waves-light",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$router.replace({ name: "availability" })
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.$translate(
                          "availability.availability_date_blocked_confirm_btn"
                        )
                      ) +
                      "\n                "
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }