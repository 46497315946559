import { render, staticRenderFns } from "./KryptonConnectPromoBanner.vue?vue&type=template&id=148a10cf&scoped=true&"
import script from "./KryptonConnectPromoBanner.vue?vue&type=script&lang=js&"
export * from "./KryptonConnectPromoBanner.vue?vue&type=script&lang=js&"
import style0 from "./KryptonConnectPromoBanner.vue?vue&type=style&index=0&id=148a10cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148a10cf",
  null
  
)

/* custom blocks */
import block0 from "./../translations/connect.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fkrypton-connect%2Fcomponents%2FKryptonConnectPromoBanner.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('148a10cf')) {
      api.createRecord('148a10cf', component.options)
    } else {
      api.reload('148a10cf', component.options)
    }
    module.hot.accept("./KryptonConnectPromoBanner.vue?vue&type=template&id=148a10cf&scoped=true&", function () {
      api.rerender('148a10cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/krypton-connect/components/KryptonConnectPromoBanner.vue"
export default component.exports