import { render, staticRenderFns } from "./ScreenFeedback.vue?vue&type=template&id=faae68e0&scoped=true&"
import script from "./ScreenFeedback.vue?vue&type=script&lang=js&"
export * from "./ScreenFeedback.vue?vue&type=script&lang=js&"
import style0 from "./ScreenFeedback.vue?vue&type=style&index=0&id=faae68e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faae68e0",
  null
  
)

/* custom blocks */
import block0 from "@/features/onboarding-lesson/translations/feedback.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fonboarding-lesson%2Fcomponents%2FScreenFeedback.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('faae68e0')) {
      api.createRecord('faae68e0', component.options)
    } else {
      api.reload('faae68e0', component.options)
    }
    module.hot.accept("./ScreenFeedback.vue?vue&type=template&id=faae68e0&scoped=true&", function () {
      api.rerender('faae68e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/onboarding-lesson/components/ScreenFeedback.vue"
export default component.exports