var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "register-new" } }, [
    _c(
      "div",
      { staticClass: "wrapper", attrs: { id: "registration-complete-mobile" } },
      [
        _c(
          "div",
          { staticClass: "content__area", class: _vm.getSuccessClass },
          [
            _c("img", {
              attrs: {
                id: "k-logo",
                src: "/img/registration/k-logo.svg",
                alt: "k-logo"
              }
            }),
            _vm._v(" "),
            _c("h2", {
              staticClass: "content__title content__title--desktop",
              domProps: {
                innerHTML: _vm._s(_vm.$translate("register.new_complete.title"))
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer max-height-mobile" }, [
          _c("div", { staticClass: "content center-align" }, [
            _c("h2", {
              staticClass: "content__title content__title--mobile",
              domProps: { innerHTML: _vm._s(_vm.getTitle) }
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.$translate("register.new_complete.content")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions flex-grid space-between" }, [
              _vm.beforeInstallPromptEvt
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-verify",
                      attrs: {
                        disabled: _vm.isDownloadCTAClicked,
                        "data-id": "registration-complete-cta"
                      },
                      on: { click: _vm.doGetTheApp }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$translate("register.new_complete.get_the_app")
                          ) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drawer-icon" }, [
      _c("img", {
        staticStyle: { "max-width": "205px" },
        attrs: {
          src: "/img/registration/success/apv-preview-new.png",
          alt: "Registration Success"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }