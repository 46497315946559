import { render, staticRenderFns } from "./onboarding-lesson-reminder-modal.vue?vue&type=template&id=19fbbff9&scoped=true&"
import script from "./onboarding-lesson-reminder-modal.vue?vue&type=script&lang=js&"
export * from "./onboarding-lesson-reminder-modal.vue?vue&type=script&lang=js&"
import style0 from "./onboarding-lesson-reminder-modal.vue?vue&type=style&index=0&id=19fbbff9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19fbbff9",
  null
  
)

/* custom blocks */
import block0 from "../translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fonboarding-lesson-reminder%2Fcomponents%2Fonboarding-lesson-reminder-modal.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19fbbff9')) {
      api.createRecord('19fbbff9', component.options)
    } else {
      api.reload('19fbbff9', component.options)
    }
    module.hot.accept("./onboarding-lesson-reminder-modal.vue?vue&type=template&id=19fbbff9&scoped=true&", function () {
      api.rerender('19fbbff9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/onboarding-lesson-reminder/components/onboarding-lesson-reminder-modal.vue"
export default component.exports