import { render, staticRenderFns } from "./chat-bubble.vue?vue&type=template&id=62daf082&"
import script from "./chat-bubble.vue?vue&type=script&lang=js&"
export * from "./chat-bubble.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62daf082')) {
      api.createRecord('62daf082', component.options)
    } else {
      api.reload('62daf082', component.options)
    }
    module.hot.accept("./chat-bubble.vue?vue&type=template&id=62daf082&", function () {
      api.rerender('62daf082', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/account/support-v2/components/chat-bubble.vue"
export default component.exports