import { render, staticRenderFns } from "./kryptonConnectOverview.vue?vue&type=template&id=01b7c28a&scoped=true&"
import script from "./kryptonConnectOverview.vue?vue&type=script&lang=js&"
export * from "./kryptonConnectOverview.vue?vue&type=script&lang=js&"
import style0 from "./kryptonConnectOverview.vue?vue&type=style&index=0&id=01b7c28a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b7c28a",
  null
  
)

/* custom blocks */
import block0 from "@/features/krypton-connect/translations/connect.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fkrypton-connect%2Fpages%2FkryptonConnectOverview.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01b7c28a')) {
      api.createRecord('01b7c28a', component.options)
    } else {
      api.reload('01b7c28a', component.options)
    }
    module.hot.accept("./kryptonConnectOverview.vue?vue&type=template&id=01b7c28a&scoped=true&", function () {
      api.rerender('01b7c28a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/krypton-connect/pages/kryptonConnectOverview.vue"
export default component.exports