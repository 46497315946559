var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "interview" } },
    [
      _c("md-new-navbar", { attrs: { redirect: "profile" } }),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            { staticClass: "container", attrs: { id: "content" } },
            [
              _c("lesson-shortcut", {
                attrs: { "translation-key": "interview" },
                model: {
                  value: _vm.LESSONS.INTERVIEW,
                  callback: function($$v) {
                    _vm.$set(_vm.LESSONS, "INTERVIEW", $$v)
                  },
                  expression: "LESSONS.INTERVIEW"
                }
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.hasInterview
                      ? _vm.$translate("interview.header")
                      : _vm.$translate("interview.new_header_add")
                  )
                )
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: { src: "/img/interview.svg", alt: "interview" }
              }),
              _vm._v(" "),
              _c(
                "vform",
                { ref: "form" },
                [
                  _vm.errors.has("interview")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                        },
                        [
                          _c("small", [
                            _vm._v(_vm._s(_vm.errors.first("interview")))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.interviewItems, function(interview, index) {
                    return _c("md-krypton-interview-input", {
                      key: "interview-item-" + index + "-" + interview.answer,
                      staticClass: "margin-bottom-xl",
                      attrs: { interview: interview, index: index },
                      on: { "item-clicked": _vm.itemClicked }
                    })
                  }),
                  _vm._v(" "),
                  _vm.canAddInterview
                    ? _c(
                        "div",
                        { staticClass: "add-mode" },
                        [
                          _c("md-krypton-interview-input", {
                            attrs: { index: _vm.nextInterviewIndex },
                            on: { "item-clicked": _vm.itemClicked }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("video-shortcut", {
        model: {
          value: _vm.LESSONS.INTERVIEW,
          callback: function($$v) {
            _vm.$set(_vm.LESSONS, "INTERVIEW", $$v)
          },
          expression: "LESSONS.INTERVIEW"
        }
      }),
      _vm._v(" "),
      _c("interviewEdit", {
        attrs: {
          "is-shown": _vm.isInterviewEditShown,
          "selected-item": _vm.selectedItem
        },
        on: {
          close: function() {
            return (_vm.isInterviewEditShown = false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }