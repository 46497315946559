var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", attrs: { id: "verify" } },
    [
      [
        _c("div", { staticClass: "content__area" }, [
          _c("img", {
            attrs: {
              id: "k-logo",
              src: "/img/registration/k-logo.svg",
              alt: "k-logo"
            }
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "content__title content__title--desktop" }, [
            _vm._v(_vm._s(_vm.$translate("register.verify.title")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content__area padding-bottom" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "content__title content__title--mobile" }, [
              _vm._v(_vm._s(_vm.$translate("register.verify.title")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "context" }, [
              _c("div", { staticClass: "subtitle" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$translate("register.verify.description")) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.callingCodes.length
                ? _c(
                    "div",
                    { staticClass: "margin-top-lg" },
                    [
                      _c(
                        "apv-form",
                        {
                          attrs: { "data-test": "submit-form" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              _vm.isRequestCode
                                ? _vm.verifySMSCode()
                                : _vm.requestSMSCode()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "verification-field-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "country-field-container baselined",
                                  class: { disable: _vm.isRequestCode },
                                  attrs: { id: "land" }
                                },
                                [
                                  _c("country-picker-autocomplete", {
                                    staticClass:
                                      "country-picker textfield-box-lg verify-dropdown",
                                    class: {
                                      "country-picker--APV_REG_REDESIGN": true
                                    },
                                    attrs: {
                                      "on-registration": "",
                                      caret: true,
                                      disabled: _vm.isRequestCode
                                    },
                                    on: { select: _vm.countrySelected },
                                    model: {
                                      value: _vm.payload.code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.payload, "code", $$v)
                                      },
                                      expression: "payload.code"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mobile-field-container-new baselined",
                                  class: { disable: _vm.isRequestCode }
                                },
                                [
                                  _c("apv-input", {
                                    ref: "clear",
                                    staticClass: "textfield-box-lg phoned",
                                    staticStyle: { "margin-top": "-20px" },
                                    attrs: {
                                      type: "text",
                                      label: _vm.$translate(
                                        "register.verify.mobile_number"
                                      ),
                                      error: _vm.errors.first("mobile"),
                                      disabled: _vm.isRequestCode,
                                      required: ""
                                    },
                                    on: {
                                      focus: _vm.hideButton,
                                      blur: _vm.showButton
                                    },
                                    model: {
                                      value: _vm.payload.phone_number,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.payload,
                                          "phone_number",
                                          $$v
                                        )
                                      },
                                      expression: "payload.phone_number"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "sms-field-container baselined breaked"
                            },
                            [
                              _vm.isRequestCode
                                ? _c("apv-input", {
                                    staticClass: "smscode textfield-box-lg",
                                    attrs: {
                                      "data-test": "sms-code",
                                      type: "tel",
                                      error: _vm.errors.first("verified"),
                                      label: _vm.$translate(
                                        "setting.sms_code_label"
                                      )
                                    },
                                    on: { focus: _vm.scrollDown },
                                    model: {
                                      value: _vm.payload.smsCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.payload, "smsCode", $$v)
                                      },
                                      expression: "payload.smsCode"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isRequestCode
                                ? _c("ul", { staticClass: "resend-text" }, [
                                    _c("li", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$translate(
                                            "register.verify.resend"
                                          )
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.resendSMSCode($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("span", { staticClass: "special" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$translate(
                                                "register.verify.resend_btn"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "actions flex-grid justify-content-center" },
              [
                _vm.isRequestCode
                  ? [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-verify",
                          attrs: {
                            disabled:
                              _vm.isLoading ||
                              !_vm.payload.phone_number ||
                              !_vm.payload.smsCode
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.verifySMSCode($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$translate("register.verify.continue"))
                          )
                        ]
                      )
                    ]
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            disabled: _vm.isLoading || !_vm.payload.phone_number
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.requestSMSCode($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$translate("register.verify.continue"))
                          )
                        ]
                      )
                    ]
              ],
              2
            )
          ])
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }