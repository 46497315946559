<script>
/**
 * Input Field e.g. text, password, email, number, etc.
 *
 * @param string value - Default value of the input.
 * @param string type - Can be text, password, email, or any other normal type for input.
 * @param string label - The label to be shown for the input.
 * @param string placeholder - Placeholder to be shown for the input. Useful for giving hints.
 * @param string autocomplete - Set value to "on" or "off" depending on how you want the input to behave.
 * @param string tabindex - Numeric string. Sets the tabindex for the input.
 * @param string maxlength - Numberic string. Max no. of characters or max value for the input.
 * @param  boolean disabled - Disables the input.
 * @param boolean filled - Changes the appearance of the input from transparent to a light grey background.
 * @param boolean dark - Changes the appearance of the input from transparent to a white background.
 */

export default {
  data() {
    return {
      isShowedPassword: false
    };
  },
  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    tabindex: {
      type: String,
      default: null
    },
    maxlength: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPasswordImgSrc () {
      return this.checkingPasswordType === 'password' ? '/img/eyes/eye.svg' : '/img/eyes/eye-slash.svg'
    },
    checkingPasswordType () {
      if(this.type === 'password' && !this.isShowedPassword) {
        return 'password'
      } else if (this.type === 'password' && this.isShowedPassword) {
        return 'text'
      }
    },
    hasError () {
      return this.error !== null
    },
    computedClasses () {
      return {
        filled: this.filled,
        dark: this.dark,
        'has-value': this.value,
        'has-error': this.error || false
      }
    }
  },
  mounted () {
    if (this.value) {
      this.addActiveClass('label')
    }
  },
  methods: {
    clearInput () {
      this.$emit('input', null)
      this.$emit('onClearInput')
      this.$refs.inputfield.value = null
      this.removeActiveClass('clearcta')
    },
    focusInput () {
      this.$refs.inputfield.focus()
    },
    clearAndFocusInput () {
      this.clearInput()
      this.focusInput()
    },
    showPassword () {
      if(this.type !== 'password') {
        return
      }

      this.isShowedPassword = !this.isShowedPassword
    },
    addActiveClass (element = null) {
      if (element) {
        this.$refs[element]?.classList.add('active')
      }
    },
    removeActiveClass (element = null) {
      if (element) {
        this.$refs[element]?.classList.remove('active')
      }
    },
    handleInputEvent (event) {
      this.$emit('input', event.target.value)
      if (event.target.value) {
        this.addActiveClass('clearcta')
      } else {
        this.removeActiveClass('clearcta')
      }
    },
    handleFocusEvent (event) {
      this.$emit('focus', event)
      if (this.value) {
        this.addActiveClass('clearcta')
      }
    },
    handleBlurEvent (event) {
      this.$emit('blur', event)
    }
  }
}
</script>

<template>
  <div class="input-container" :class="{ 'has-error': hasError }">
    <div class="input-field apv-input-field">
     <input
        v-if="type === 'password'"
        ref="inputfield"
        class="apv-input-field--input"
        :type="checkingPasswordType"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :maxlength="maxlength"
        :disabled="disabled"
        :class="computedClasses"
        :value="value"
        @input="handleInputEvent"
        @focus="handleFocusEvent"
        @blur="handleBlurEvent"
      >
      <input
        v-else
        ref="inputfield"
        class="apv-input-field--input"
        :type="type"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :maxlength="maxlength"
        :disabled="disabled"
        :class="computedClasses"
        :value="value"
        @input="handleInputEvent"
        @focus="handleFocusEvent"
        @blur="handleBlurEvent"
      >
      <label ref="label">{{ label }}</label>
      <img
        v-if="type !== 'password'"
        ref="clearcta"
        src="/img/registration/input/clearcta.svg"
        alt="X"
        class="clear-cta"
        @click="clearAndFocusInput"
      >
      <img
        v-if="type === 'password'"
        class="show-hide-cta"
        :src="showPasswordImgSrc"
        @click="showPassword"
      >
    </div>
    <div v-if="error" class="error-msg-txt" data-test="input-error-msg">{{ error }}</div>
  </div>
</template>

<style lang="scss" scoped>

.APV-onbo-redesign {

    .apv-input-field {

        input[type="email"].apv-input-field--input,
        input[type="password"].apv-input-field--input
        {
            color: #2F3740;
            height: 70px;
            line-height: 26px;
        }
    }
}

.apv-input-field {
  input[type="text"].apv-input-field--input,
  input[type="email"].apv-input-field--input,
  input[type="password"].apv-input-field--input,
  input[type="tel"].apv-input-field--input {
    width: 100%;
    height: 70px;
    margin: 0 0;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    border: 1px solid #CED6E0;
    border-radius: 12px;
    color: #52575B;
    font-size: 16px;
    font-family: 'Proxima Nova Semibold';

    &.has-value:not([readonly]) {
      border: 1px solid #0c0c0d;
      color: #2F3740;
    }

    &:focus:not([readonly]) {
      border: 2px solid #2F3740;
    }

    &.has-value:not([readonly]) + label.active {
      color: #52575B;
    }

    &:focus:not([readonly]) + label.active {
      color: #2F3740;
    }

    & + label {
      color: #52575B;
      top: 12px;
      left: 16px;
      font-family: 'Proxima Nova Semibold';
      font-size: 16px;
    }
    & + label.active {
      top: -10px;
      transform: translate(0px, 0px) scale(1);
      left: 11px; // Deduct the 5px padding from the minimized label
      color: #2F3740;
      background: #FFFFFF;
      padding-left: 5px;
      padding-right: 5px;
      font-family: 'Proxima Nova Medium';
      font-size: 12px;
    }
  }

  .clear-cta {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
    display: none;

    &.active {
      display: block;
    }
  }

  .show-hide-cta {
    position: absolute;
    top: 14px;
    right: 4px;
    cursor: pointer;
  }
}

.has-error {
  label {
    color: #FF0033 !important;
  }
  .apv-input-field--input {
    border: 2px solid #FF0033 !important;
  }
}
.error-msg-txt {
  color: #FF0033;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}
</style>
