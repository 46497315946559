import { render, staticRenderFns } from "./AffiliateRewardPopup.vue?vue&type=template&id=7ff4db87&scoped=true&"
import script from "./AffiliateRewardPopup.vue?vue&type=script&lang=js&"
export * from "./AffiliateRewardPopup.vue?vue&type=script&lang=js&"
import style0 from "./AffiliateRewardPopup.vue?vue&type=style&index=0&id=7ff4db87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff4db87",
  null
  
)

/* custom blocks */
import block0 from "@/features/affiliate/translations/popup.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Faffiliate%2Fcomponents%2Fmodals%2FAffiliateRewardPopup.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ff4db87')) {
      api.createRecord('7ff4db87', component.options)
    } else {
      api.reload('7ff4db87', component.options)
    }
    module.hot.accept("./AffiliateRewardPopup.vue?vue&type=template&id=7ff4db87&scoped=true&", function () {
      api.rerender('7ff4db87', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/affiliate/components/modals/AffiliateRewardPopup.vue"
export default component.exports