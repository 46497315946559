var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container center-align padding-x-xl padding-y-xl",
      attrs: { id: "event-declined-not-available" }
    },
    [
      _vm.step === _vm.PAGE_STEPS.NOT_AVAILABLE_1
        ? _c(
            "div",
            { attrs: { id: "event-declined-step-1" } },
            [
              _c("h1", { staticClass: "title strong margin-y-none" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$translate(
                        "events.decline_popup.not_available_2_title",
                        { client_name: _vm.clientName }
                      )
                    ) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "margin-y-lg" }, [
                _c("small", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$translate(
                          "events.decline_popup.not_available_2_note",
                          { client_name: _vm.clientName }
                        )
                      ) +
                      "\n      "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._t("input"),
              _vm._v(" "),
              _c("p", { staticClass: "margin-y-none" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$translate(
                        "events.decline_popup.not_comfortable_request"
                      )
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "margin-y-md",
                  staticStyle: { color: "#8995A3", "font-size": "14px" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$translate(
                        "events.decline_popup.not_comfortable_request_explainer"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "white display-block inactive krypton-pink-text margin-none setting-indicator waves-dark waves-effect",
                  staticStyle: { "font-size": "14px" },
                  attrs: { id: "event-declined-not-available-blacklist" },
                  on: { click: _vm.doDeclineBlacklist }
                },
                [
                  _c("i", {
                    staticClass:
                      "mdi mdi-close-circle krypton-pink-text valign-middle",
                    staticStyle: { "font-size": "23px" }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$translate(
                          "events.decline_popup.not_comfortable_request_button"
                        )
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step === _vm.PAGE_STEPS.NOT_AVAILABLE_2
        ? _c("div", { attrs: { id: "event-declined-step-2" } }, [
            _c("h1", { staticClass: "title strong margin-top-none" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$translate(
                      "events.decline_popup.not_available_1_title",
                      {
                        meeting_start_at: _vm.eventDate
                      }
                    )
                  ) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("small", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$translate(
                        "events.decline_popup.not_available_1_note",
                        {
                          meeting_start_at: _vm.eventDate
                        }
                      )
                    ) +
                    "\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "availability-img responsive-img",
              staticStyle: { "min-height": "312.5px" },
              attrs: { src: "/img/events/availability.png", alt: "" }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }