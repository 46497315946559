import { render, staticRenderFns } from "./CopyIcon.vue?vue&type=template&id=4fcce5de&"
import script from "./CopyIcon.vue?vue&type=script&lang=js&"
export * from "./CopyIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4fcce5de')) {
      api.createRecord('4fcce5de', component.options)
    } else {
      api.reload('4fcce5de', component.options)
    }
    module.hot.accept("./CopyIcon.vue?vue&type=template&id=4fcce5de&", function () {
      api.rerender('4fcce5de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/billing/components/CopyIcon.vue"
export default component.exports