var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ios-main" }, [
    _c("div", { staticClass: "contain" }, [
      _c("div", { staticClass: "content" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("appName")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("appDescription")))]),
        _vm._v(" "),
        _c("img", {
          staticClass: "intro-img",
          attrs: { src: "/img/pwa/screen/ios/new-screen.jpg" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "steps" }, [
          _c("ul", [
            _c("li", [
              _c("img", {
                staticClass: "forward-img one",
                attrs: { src: "/img/pwa/icon/ios/Pfad20029.svg" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("1.) " + _vm._s(_vm.$t("contentLine1")))])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                staticClass: "forward-img two",
                attrs: { src: "/img/pwa/icon/ios/Gruppe13534.svg" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("2.) " + _vm._s(_vm.$t("contentLine2")))])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }