import { render, staticRenderFns } from "./weight.vue?vue&type=template&id=d922c82e&"
import script from "./weight.vue?vue&type=script&lang=js&"
export * from "./weight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@components/register/translations/weight.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Fcomponents%2Fregister%2Fbasic%2Fweight.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d922c82e')) {
      api.createRecord('d922c82e', component.options)
    } else {
      api.reload('d922c82e', component.options)
    }
    module.hot.accept("./weight.vue?vue&type=template&id=d922c82e&", function () {
      api.rerender('d922c82e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/register/basic/weight.vue"
export default component.exports