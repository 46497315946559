import { render, staticRenderFns } from "./ScreenVideo.vue?vue&type=template&id=1fa05300&scoped=true&"
import script from "./ScreenVideo.vue?vue&type=script&lang=js&"
export * from "./ScreenVideo.vue?vue&type=script&lang=js&"
import style0 from "./ScreenVideo.vue?vue&type=style&index=0&id=1fa05300&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa05300",
  null
  
)

/* custom blocks */
import block0 from "@/features/onboarding-lesson/translations/video.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fonboarding-lesson%2Fcomponents%2FScreenVideo.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fa05300')) {
      api.createRecord('1fa05300', component.options)
    } else {
      api.reload('1fa05300', component.options)
    }
    module.hot.accept("./ScreenVideo.vue?vue&type=template&id=1fa05300&scoped=true&", function () {
      api.rerender('1fa05300', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/onboarding-lesson/components/ScreenVideo.vue"
export default component.exports