import VueRouter from 'vue-router'

const routes = [
  // Force logout path
  {
    path: '/logout',
    name: 'logout',
    component: Vue.component('logout')
  },
  $h.getConstant('routes/login'),
  $h.getConstant('routes/forgot-password', 'forgotPassword'),
  $h.getConstant('routes/forgot-password', 'forgotPasswordSuccess'),
  $h.getConstant('routes/request-magic-link', 'requestMagicLink'),
  $h.getConstant('routes/request-magic-link', 'requestMagicLinkSuccess'),
  $h.getConstant('routes/reset-password', 'resetPassword'),
  $h.getConstant('routes/account'),
  $h.getConstant('routes/legal', 'privacy'),
  $h.getConstant('routes/legal', 'philosophy'),
  $h.getConstant('routes/legal', 'termsConditions'),
  $h.getConstant('routes/register', 'start'),
  $h.getConstant('routes/register', 'register'),
  $h.getConstant('routes/reactivation', 'reactivation'),
  $h.getConstant('routes/reactivation', 'reactivationSuccess'),
  $h.getConstant('routes/referral', 'referral'),
  $h.getConstant('routes/not-found', 'notFound')
]

const VueRouterInstance = new VueRouter({
  mode: 'history',
  routes: routes,
  // ////////////////
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default VueRouterInstance
