<template>
  <div class="ios-main">
    <div class="contain">
      <div class="content">
        <h2>{{ $t('appName') }}</h2>
        <p>{{ $t('appDescription') }}</p>
        <img class="intro-img" src="/img/pwa/screen/ios/new-screen.jpg">

        <div class="steps">
          <ul>
            <li>
              <img class="forward-img one" src="/img/pwa/icon/ios/Pfad20029.svg">
              <p>1.) {{ $t('contentLine1') }}</p>
            </li>
            <li>
              <img class="forward-img two" src="/img/pwa/icon/ios/Gruppe13534.svg">
              <p>2.) {{ $t('contentLine2') }}</p>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<i18n src="@/features/pwa-animation/translations/ios.json"></i18n>

<script>
export default {
  computed: {
    appUrl () {
      return process.env.KRYPTON_APP_URL
    },
    PHOTO_FALLBACK_A2_2720 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2720')
    },
    gifGuideUrl () {
      return this.PHOTO_FALLBACK_A2_2720 ? 'https://d1muixarkip6az.cloudfront.net/apv/ios-v2.gif' : 'https://res.cloudinary.com/ama-production/image/upload/q_auto/v1636360994/apv/ios-v2.gif'
      // return 'https://res.cloudinary.com/ama-production/image/upload/q_auto/v1636360994/apv/ios-v2.gif'
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes topToBottom {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, -20px);
  }
}

.ios-main {
  background: rgb(118,56,250);
  background: linear-gradient(124deg, rgba(118,56,250,1) 0%, rgba(211,0,197,1) 50%, rgba(255,0,115,1) 100%);
  position: relative;
  height: 100vh;
  width: 100%;

  .contain {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    bottom: 0;
    height: 92%;
    overflow: scroll;
    padding: 32px 24px 0 24px;
    position: fixed;
    text-align: center;
    width: 100%;

    .content { margin: 0 auto; width: 360px;

      @media (max-width: 420px) { width: 100%; }

      .intro-img { width: 100%; }
      h2 { color: #2E353D; font-size: 38px; line-height: 44px; margin: 0 0 32px 0; }
      p { color: #2E353D; font-size: 18px; line-height: 26px; margin-bottom: 24px; }

      .steps {
        margin: 24px 0 20px 0; text-align: left;

        ul { margin: 0; padding: 0; }

        li {
          display: inline-flex; margin-bottom: 12px; width: 100%;

          p { margin: 0; padding: 0; }
          .forward-img { display: inline-flex; margin-right: 8px; vertical-align: text-bottom; }
          .one { height: 24px; }
          .two { height: 20px; margin-top: 3px; }

        }
      }
    }
  }
}

</style>
