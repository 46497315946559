<template>
    <transition name="slide">
        <div id="event-declined" v-if="isViewReady">
            <div v-if="step === PAGE_STEPS.DECLINED">
                <div class="padding">
                    <span class="close grey-text text-darken-1"
                          @click.prevent="goBack">
                        <i class="material-icons">close</i>
                    </span>
                </div>
                <div class="container center-align padding-x-xl padding-y-x3">
                    <h1 class="title strong margin-top-none">
                        {{ $translate('events.decline_popup.declined_title') }}
                    </h1>
                    <p>
                        <small>
                            {{ $translate('events.decline_popup.declined_note') }}
                        </small>
                    </p>
                    <div v-if="error_form.decline"
                        class="grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align">
                        <small>{{ $translate('events.decline_popup.decline_error') }}</small>
                    </div>
                    <div class="input-radio left-align">
                        <input class="with-gap" v-model="answer" name="answer" type="radio" value="1"  />
                        <label @click.prevent="answer = 1">
                            {{ $translate('events.decline_popup.declined_option_1') }}
                        </label>
                    </div>
                    <div class="input-radio left-align">
                        <input class="with-gap" v-model="answer" name="answer" type="radio" value="2"  />
                        <label @click.prevent="answer = 2">
                            {{ $translate('events.decline_popup.declined_option_2') }}
                        </label>
                    </div>

                    <div class="navigator row margin-top-lg">
                        <button class="btn btn-large btn-secondary waves-effect waves-dark"
                                @click="$router.go(-1)">
                            {{ $translate('events.buttons.back') }}
                        </button>
                        <button class="btn btn-large btn-krypton-pink waves-effect waves-light"
                                @click="nextStep()">
                            {{ $translate('events.buttons.continue') }}
                        </button>
                    </div>

                    <p>
                        <a href="#" class="waves-effect waves-dark" @click.prevent="showTooltip()">
                            {{ $translate('events.decline_popup.explanation_link') }}
                        </a>
                    </p>
                </div>
            </div>
            <!-- I am not available -->
            <div v-if="step === PAGE_STEPS.NOT_AVAILABLE_1">
                <div class="padding">
                    <span class="close grey-text text-darken-1"
                          @click.prevent="$router.go(-1)">
                        <i class="material-icons">close</i>
                    </span>
                </div>
                <div class="container center-align padding-x-xl padding-y-x3">
                    <h1 class="title strong margin-top-none">
                        {{
                            $translate('events.decline_popup.not_available_1_title', {
                                meeting_start_at: eventDate
                            })
                        }}
                    </h1>
                    <img src="/img/events/availability.png" class="availability-img responsive-img" alt="" />
                    <p>
                        <small>
                            {{ $translate('events.decline_popup.not_available_1_note', {
                                meeting_start_at: eventDate
                             }) }}
                        </small>
                    </p>
                    <div class="navigator row margin-top-lg">
                        <button class="btn btn-large btn-secondary"
                                @click="prevStep()">
                            {{ $translate('events.buttons.back') }}
                        </button>
                        <button class="btn btn-large btn-krypton-pink waves-effect waves-light"
                                @click="nextStep()">
                            {{ $translate('events.buttons.continue') }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="step === PAGE_STEPS.NOT_AVAILABLE_2">
                <div class="padding">
                    <span class="close grey-text text-darken-1"
                          @click.prevent="$router.back()">
                        <i class="material-icons">close</i>
                    </span>
                </div>
                <div class="container center-align padding-x-xl padding-y-x3">
                    <h1 class="title strong margin-top-none">
                        {{ $translate('events.decline_popup.not_available_2_title', { client_name: selectedEvent.client.name }) }}
                    </h1>
                    <p>
                        <small>
                            {{ $translate('events.decline_popup.not_available_2_note', { client_name: selectedEvent.client.name }) }}
                        </small>
                    </p>
                    <div v-if="error_form.not_available"
                        class="grey darken-1 padding-md border-rad-sm margin-bottom-md left-align">
                        <small class="white-text">{{ $translate('events.decline_popup.not_available_error', { client_name: selectedEvent.client.name }) }}</small>
                    </div>
                    <md-textarea type="multiline"
                        :force-active="true"
                        v-model="message"
                        :maxlength="notAvailableMaxChar"
                        :label="$translate('events.decline_popup.not_available_textarea_label')"
                        :placeholder="$translate('events.decline_popup.not_available_textarea_placeholder', { client_name: selectedEvent.client.name })">
                        <div>
                            <span class="input-helper grey-text text-darken-1 right">
                                {{ getNotAvailableMaxChar(message) }}
                            </span>
                        </div>
                    </md-textarea>
                    <div class="navigator row margin-top-lg">
                        <button class="btn btn-large btn-secondary waves-effect waves-dark"
                                @click="prevStep()">
                            {{ $translate('events.buttons.back') }}
                        </button>
                        <button class="btn btn-large btn-krypton-pink waves-effect waves-light"
                                @click="decline()">
                            <span v-if="!isSending">{{ $translate('events.buttons.decline_v2') }}</span>
                            <div class="preloader-wrapper small active" v-else>
                              <div class="spinner-layer spinner-green-only">
                                <div class="circle-clipper left">
                                  <div class="circle"></div>
                                </div><div class="gap-patch">
                                  <div class="circle"></div>
                                </div><div class="circle-clipper right">
                                  <div class="circle"></div>
                                </div>
                              </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <!-- I am Uncomfortable -->
            <div v-if="step === PAGE_STEPS.NOT_COMFORTABLE">
                <div class="padding">
                    <span class="close grey-text text-darken-1 waves-effect waves-dark"
                          @click.prevent="$router.back()">
                        <i class="material-icons">close</i>
                    </span>
                </div>
                <div class="container center-align padding-x-xl padding-y-x3">
                    <h1 class="title strong margin-top-none">
                        {{ $translate('events.decline_popup.not_comfortable_title') }}
                    </h1>
                    <p>
                        <small>
                            {{
                                $translate('events.decline_popup.not_comfortable_note', {
                                    client_name: selectedEvent.client.name
                                })
                            }}
                        </small>
                    </p>
                    <div v-if="error_form.not_comfortable"
                        class="grey darken-1 padding-md border-rad-sm margin-bottom-md left-align">
                        <small class="white-text">{{ $translate('events.decline_popup.not_comfortable_error') }}</small>
                    </div>
                    <md-textarea type="multiline"
                        :force-active="true"
                        v-model="message"
                        :maxlength="answerMaxChar"
                        :label="$translate('events.decline_popup.not_comfortable_textarea_label')"
                        :placeholder="$translate('events.decline_popup.not_comfortable_textarea_placeholder')">
                        <div>
                            <span class="input-helper grey-text text-darken-1 right">
                                {{ getMaxChar(message) }}
                            </span>
                        </div>
                    </md-textarea>
                    <div class="navigator row margin-top-lg">
                        <button class="btn btn-large btn-secondary waves-effect waves-dark"
                                @click="prevStep()">
                            {{ $translate('events.buttons.back') }}
                        </button>
                        <button class="btn btn-large btn-krypton-pink waves-effect waves-light"
                                @click="decline()">
                            <span v-if="!isSending">{{ $translate('events.buttons.decline_v2') }}</span>
                            <div class="preloader-wrapper small active" v-else>
                              <div class="spinner-layer spinner-green-only">
                                <div class="circle-clipper left">
                                  <div class="circle"></div>
                                </div><div class="gap-patch">
                                  <div class="circle"></div>
                                </div><div class="circle-clipper right">
                                  <div class="circle"></div>
                                </div>
                              </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="declined-page" v-if="step === PAGE_STEPS.FINISH">
                <div id="heading" class="krypton-grey white-text padding-x-md padding-y-x3">
                    <div>{{ $translate('events.decline_popup.finish_title') }}</div>
                    <i class="cancel-icon material-icons">cancel</i>
                </div>
                <div class="padding-xl padding-x-xl padding-y-x3">
                    <p>
                    {{
                        answer == 1 ? $translate('events.decline_popup.finish_note_1') : $translate('events.decline_popup.finish_note_2')
                    }}
                    </p>
                    <button class="btn btn-full btn-large margin-top-lg waves-effect waves-dark"
                            @click.prevent="$router.replace({ name: 'events' });">
                        {{ $translate('events.buttons.go_to_dashboard') }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NullSelectCheck from '../mixins/nullSelectCheck.js';

import { mapGetters } from 'vuex';

const ApiProvider   = $h.getProvider('ApiProvider');
const LocaleProvider = $h.getProvider('LocaleProvider');
const PAGE_STEPS = {
    DECLINED: 'declined',
    NOT_AVAILABLE_1: 'not-available-1',
    NOT_AVAILABLE_2: 'not-available-2',
    NOT_COMFORTABLE: 'not-comfortable',
    FINISH: 'finish'
};

export default {
    name: 'event-declined',
    props: ['event'],
    mixins: [NullSelectCheck],
    data() {
        return {
            PAGE_STEPS,

            notAvailableMaxChar: 160,
            answerMaxChar: 500,
            message: '',
            answer: null,
            isDeclined: false,
            step: PAGE_STEPS.DECLINED,

            error_form: {
                decline: false,
                not_available: false,
                not_comfortable: false,
            },

            isSending: false,
        };
    },
    methods: {
        nextStep() {
            switch(this.step) {
                case PAGE_STEPS.DECLINED:
                    if (this.answer == 1) {
                        this.error_form.not_available = false;
                        this.message = '';
                        this.step = PAGE_STEPS.NOT_AVAILABLE_1;
                    } else if (this.answer == 2) {
                        this.error_form.not_comfortable = false;
                        this.message = '';
                        this.step = PAGE_STEPS.NOT_COMFORTABLE;
                    } else {
                        this.$notifications.toast(this.$translate('events.decline_popup.decline_error'), 5000);
                    }
                    break;
                case PAGE_STEPS.NOT_AVAILABLE_1:
                    this.step = PAGE_STEPS.NOT_AVAILABLE_2;
                    break;
                case PAGE_STEPS.NOT_AVAILABLE_2:
                    this.step = PAGE_STEPS.FINISH;
                    break;
                case PAGE_STEPS.NOT_COMFORTABLE:
                    this.step = PAGE_STEPS.FINISH;
                    break;
            }
        },
        prevStep() {
            switch(this.step) {
                case PAGE_STEPS.NOT_AVAILABLE_1:
                    this.step = PAGE_STEPS.DECLINED;
                    break;
                case PAGE_STEPS.NOT_AVAILABLE_2:
                    this.step = PAGE_STEPS.NOT_AVAILABLE_1;
                    break;
                case PAGE_STEPS.NOT_COMFORTABLE:
                    this.step = PAGE_STEPS.DECLINED;
                    break;
            }
        },
        decline() {
            if (this.isSending) return;

            this.error_form.not_available = false;
            this.error_form.not_comfortable = false;

            if (this.message.length < 30) {
                this.error_form.not_available = true;
                this.error_form.not_comfortable = true;
                return;
            }

            const pl = {
                id: this.selectedEvent.id,
                payload: {
                    message: this.message,
                    decline_status: this.answer
                },
            };

            this.isSending = true;
            this.$store
                .dispatch('events/decline', pl)
                .then((evt) => {
                    this.$notifications.toast(
                        this.$translate('events.decline_popup.success_toast'),
                        5000
                    );
                    this.$store.commit('events/SET_SELECTED', null);
                    this.$store.commit('events/ADD_OR_UPDATE', evt);
                    // Refetch availabilities
                    if (this.user.availability_enabled) {
                      this.$store.dispatch('availabilities/refreshAvailabilities');
                    }
                    this.$router.replace({ name: 'events' });
                })
                .finally(() => {
                    this.isSending = false;
                });
        },
        showTooltip() {
            this.$notifications.toast(
                this.$translate('events.decline_popup.declined_hint'),
                5000
            );
        },
        goBack() {
            if (!this.isSending) {
                this.$router.back();
            }
        },
    },
    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
        getCharCount() {
            return (answer) => {
                if (!answer) return 0;
                return answer.length;
            }
        },
        getMaxChar() {
            return (answer) => {
                return this.getCharCount(answer) + '/' + this.answerMaxChar;
            }
        },
        getNotAvailableMaxChar() {
            return (answer) => {
                return this.getCharCount(answer) + '/' + this.notAvailableMaxChar;
            }
        },
        eventDate() {
            return this.selectedEvent
                       ._meeting_start_at
                       .format('MMMM DD');
        },
  },
}
</script>

<style lang="scss" scoped>
#event-declined {
    & {
        position: relative;
    }
    .close {
        padding: 10px 10px 4px;
        top: 16px;
        right: 16px;
        position: absolute;
        z-index: 999;
        cursor: pointer;
    }
    .title {
        font-size: 1.375rem;
    }
    .subtitle {
        font-size: 1.125rem;
    }
    .declined-page {
        & {
            text-align: center;
        }
        #heading {
            & {
                font-weight: 900;
                font-size: 2.75rem;
                font-style: italic;
                line-height: 45px;
            }
            .ic {
                margin-top: 10px;
                filter: brightness(0) invert(1);
            }
        }
        .cancel-icon {
            font-size: 5rem;
            margin-top: 30px;
        }
    }
    .navigator {
        button {
            width: 48%;
            margin: 0 1px;
        }
    }
}
</style>
