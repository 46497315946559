import { render, staticRenderFns } from "./SuperProviderPrereqStatus.vue?vue&type=template&id=3670f5b7&scoped=true&"
import script from "./SuperProviderPrereqStatus.vue?vue&type=script&lang=js&"
export * from "./SuperProviderPrereqStatus.vue?vue&type=script&lang=js&"
import style0 from "./SuperProviderPrereqStatus.vue?vue&type=style&index=0&id=3670f5b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3670f5b7",
  null
  
)

/* custom blocks */
import block0 from "@/features/super-provider/translations/super-provider-prereq-status.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fforge%2Fama-provider-neil.noznom.tech%2Fsrc%2Fjs%2Ffeatures%2Fsuper-provider%2Fcomponents%2FSuperProviderPrereqStatus.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/ama-provider-neil.noznom.tech/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3670f5b7')) {
      api.createRecord('3670f5b7', component.options)
    } else {
      api.reload('3670f5b7', component.options)
    }
    module.hot.accept("./SuperProviderPrereqStatus.vue?vue&type=template&id=3670f5b7&scoped=true&", function () {
      api.rerender('3670f5b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/features/super-provider/components/SuperProviderPrereqStatus.vue"
export default component.exports