var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.isViewReady
      ? _c("div", { attrs: { id: "event-declined" } }, [
          _vm.step === _vm.PAGE_STEPS.DECLINED
            ? _c("div", [
                _c("div", { staticClass: "padding" }, [
                  _c(
                    "span",
                    {
                      staticClass: "close grey-text text-darken-1",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.goBack($event)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "container center-align padding-x-xl padding-y-x3"
                  },
                  [
                    _c("h1", { staticClass: "title strong margin-top-none" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$translate(
                              "events.decline_popup.declined_title"
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("small", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$translate(
                                "events.decline_popup.declined_note"
                              )
                            ) +
                            "\n                    "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.error_form.decline
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grey darken-1 white-text padding-md border-rad-sm margin-bottom-md left-align"
                          },
                          [
                            _c("small", [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.decline_popup.decline_error"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-radio left-align" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.answer,
                            expression: "answer"
                          }
                        ],
                        staticClass: "with-gap",
                        attrs: { name: "answer", type: "radio", value: "1" },
                        domProps: { checked: _vm._q(_vm.answer, "1") },
                        on: {
                          change: function($event) {
                            _vm.answer = "1"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.answer = 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$translate(
                                  "events.decline_popup.declined_option_1"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-radio left-align" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.answer,
                            expression: "answer"
                          }
                        ],
                        staticClass: "with-gap",
                        attrs: { name: "answer", type: "radio", value: "2" },
                        domProps: { checked: _vm._q(_vm.answer, "2") },
                        on: {
                          change: function($event) {
                            _vm.answer = "2"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.answer = 2
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$translate(
                                  "events.decline_popup.declined_option_2"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "navigator row margin-top-lg" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-secondary waves-effect waves-dark",
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$translate("events.buttons.back")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-krypton-pink waves-effect waves-light",
                          on: {
                            click: function($event) {
                              return _vm.nextStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$translate("events.buttons.continue")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "waves-effect waves-dark",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showTooltip()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$translate(
                                  "events.decline_popup.explanation_link"
                                )
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === _vm.PAGE_STEPS.NOT_AVAILABLE_1
            ? _c("div", [
                _c("div", { staticClass: "padding" }, [
                  _c(
                    "span",
                    {
                      staticClass: "close grey-text text-darken-1",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "container center-align padding-x-xl padding-y-x3"
                  },
                  [
                    _c("h1", { staticClass: "title strong margin-top-none" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$translate(
                              "events.decline_popup.not_available_1_title",
                              {
                                meeting_start_at: _vm.eventDate
                              }
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "availability-img responsive-img",
                      attrs: { src: "/img/events/availability.png", alt: "" }
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _c("small", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$translate(
                                "events.decline_popup.not_available_1_note",
                                {
                                  meeting_start_at: _vm.eventDate
                                }
                              )
                            ) +
                            "\n                    "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "navigator row margin-top-lg" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-large btn-secondary",
                          on: {
                            click: function($event) {
                              return _vm.prevStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$translate("events.buttons.back")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-krypton-pink waves-effect waves-light",
                          on: {
                            click: function($event) {
                              return _vm.nextStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$translate("events.buttons.continue")
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === _vm.PAGE_STEPS.NOT_AVAILABLE_2
            ? _c("div", [
                _c("div", { staticClass: "padding" }, [
                  _c(
                    "span",
                    {
                      staticClass: "close grey-text text-darken-1",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.back()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "container center-align padding-x-xl padding-y-x3"
                  },
                  [
                    _c("h1", { staticClass: "title strong margin-top-none" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$translate(
                              "events.decline_popup.not_available_2_title",
                              { client_name: _vm.selectedEvent.client.name }
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("small", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$translate(
                                "events.decline_popup.not_available_2_note",
                                { client_name: _vm.selectedEvent.client.name }
                              )
                            ) +
                            "\n                    "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.error_form.not_available
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grey darken-1 padding-md border-rad-sm margin-bottom-md left-align"
                          },
                          [
                            _c("small", { staticClass: "white-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.decline_popup.not_available_error",
                                    {
                                      client_name: _vm.selectedEvent.client.name
                                    }
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "md-textarea",
                      {
                        attrs: {
                          type: "multiline",
                          "force-active": true,
                          maxlength: _vm.notAvailableMaxChar,
                          label: _vm.$translate(
                            "events.decline_popup.not_available_textarea_label"
                          ),
                          placeholder: _vm.$translate(
                            "events.decline_popup.not_available_textarea_placeholder",
                            { client_name: _vm.selectedEvent.client.name }
                          )
                        },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message = $$v
                          },
                          expression: "message"
                        }
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-helper grey-text text-darken-1 right"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.getNotAvailableMaxChar(_vm.message)
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "navigator row margin-top-lg" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-secondary waves-effect waves-dark",
                          on: {
                            click: function($event) {
                              return _vm.prevStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$translate("events.buttons.back")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-krypton-pink waves-effect waves-light",
                          on: {
                            click: function($event) {
                              return _vm.decline()
                            }
                          }
                        },
                        [
                          !_vm.isSending
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate("events.buttons.decline_v2")
                                  )
                                )
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "preloader-wrapper small active"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-layer spinner-green-only"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "circle-clipper left" },
                                        [_c("div", { staticClass: "circle" })]
                                      ),
                                      _c("div", { staticClass: "gap-patch" }, [
                                        _c("div", { staticClass: "circle" })
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "circle-clipper right" },
                                        [_c("div", { staticClass: "circle" })]
                                      )
                                    ]
                                  )
                                ]
                              )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === _vm.PAGE_STEPS.NOT_COMFORTABLE
            ? _c("div", [
                _c("div", { staticClass: "padding" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "close grey-text text-darken-1 waves-effect waves-dark",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.back()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("close")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "container center-align padding-x-xl padding-y-x3"
                  },
                  [
                    _c("h1", { staticClass: "title strong margin-top-none" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$translate(
                              "events.decline_popup.not_comfortable_title"
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("small", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$translate(
                                "events.decline_popup.not_comfortable_note",
                                {
                                  client_name: _vm.selectedEvent.client.name
                                }
                              )
                            ) +
                            "\n                    "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.error_form.not_comfortable
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grey darken-1 padding-md border-rad-sm margin-bottom-md left-align"
                          },
                          [
                            _c("small", { staticClass: "white-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$translate(
                                    "events.decline_popup.not_comfortable_error"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "md-textarea",
                      {
                        attrs: {
                          type: "multiline",
                          "force-active": true,
                          maxlength: _vm.answerMaxChar,
                          label: _vm.$translate(
                            "events.decline_popup.not_comfortable_textarea_label"
                          ),
                          placeholder: _vm.$translate(
                            "events.decline_popup.not_comfortable_textarea_placeholder"
                          )
                        },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message = $$v
                          },
                          expression: "message"
                        }
                      },
                      [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-helper grey-text text-darken-1 right"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.getMaxChar(_vm.message)) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "navigator row margin-top-lg" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-secondary waves-effect waves-dark",
                          on: {
                            click: function($event) {
                              return _vm.prevStep()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$translate("events.buttons.back")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-large btn-krypton-pink waves-effect waves-light",
                          on: {
                            click: function($event) {
                              return _vm.decline()
                            }
                          }
                        },
                        [
                          !_vm.isSending
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$translate("events.buttons.decline_v2")
                                  )
                                )
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "preloader-wrapper small active"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "spinner-layer spinner-green-only"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "circle-clipper left" },
                                        [_c("div", { staticClass: "circle" })]
                                      ),
                                      _c("div", { staticClass: "gap-patch" }, [
                                        _c("div", { staticClass: "circle" })
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "circle-clipper right" },
                                        [_c("div", { staticClass: "circle" })]
                                      )
                                    ]
                                  )
                                ]
                              )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === _vm.PAGE_STEPS.FINISH
            ? _c("div", { staticClass: "declined-page" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "krypton-grey white-text padding-x-md padding-y-x3",
                    attrs: { id: "heading" }
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$translate("events.decline_popup.finish_title")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "cancel-icon material-icons" }, [
                      _vm._v("cancel")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "padding-xl padding-x-xl padding-y-x3" },
                  [
                    _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.answer == 1
                              ? _vm.$translate(
                                  "events.decline_popup.finish_note_1"
                                )
                              : _vm.$translate(
                                  "events.decline_popup.finish_note_2"
                                )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-full btn-large margin-top-lg waves-effect waves-dark",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$router.replace({ name: "events" })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$translate("events.buttons.go_to_dashboard")
                            ) +
                            "\n                "
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }