<template>
  <component :is="componentToShow" :data="computedData" />
</template>

<script>
import { mapGetters } from 'vuex'

import androidPwaAnimation from '@/features/pwa-animation/components/androidPwaAnimation.vue'
import iosPwaAnimation from '@/features/pwa-animation/components/iosPwaAnimation.vue'
import safariBanner from '@/features/pwa-animation/components/SafariBanner.vue'
import pwaDownloadSuccessScreen from '@/features/pwa-animation/components/pwaDownloadSuccessScreen.vue'
import pwaDownloadScreen from '@/features/pwa-animation/components/pwaDownloadScreen.vue'
import accountComponent from '@/components/tpl/account.vue'
import registerComponent from '@/components/register/register/register.vue'
import { PWA_CHECKPOINT } from '@/features/pwa-animation/constants.js'

const UserProvider = $h.getProvider('UserProvider')

const logEvent = (evtName, message) => {
  const evt = new CustomEvent(evtName, {
    detail: {
      message
    }
  })
  document.getRootNode().dispatchEvent(evt)
}

export default {
  data: () => ({
    isPWAInstalledAndroid: false
  }),
  watch: {
    showPWADownloadScreen() {
      if (this.showPWADownloadScreen === true) {
        this.updateUsageTracking(PWA_CHECKPOINT.PWA_DOWNLOAD_SCREEN)
      }
    },
    forceShowAndroidAnimation() {
      if (this.forceShowAndroidAnimation === true) {
        this.updateUsageTracking(PWA_CHECKPOINT.ANDROID_ANIMATION)
      }
    },
    forceShowAnimation() {
      // Update tracking for IOS register success
      if (this.forceShowAnimation === true && !this.forceShowAndroidAnimation && !this.showPWADownloadSuccessScreen) {
        this.updateUsageTracking(this.computedComponent)
      }
    }
  },
  mounted() {
    this.checkIfPWAInstalled()
    this.updateUsageTracking(this.computedComponent)
  },
  methods: {
    updateUsageTracking(renderedView = null) {
      logEvent('pwa-checkpoint', renderedView)
      if (this.user?.id) {
        UserProvider.updateUsageNew(this.user.id, this.isPWAMode, { download_screen: renderedView })
      }
    },
    async checkIfPWAInstalled() {
      const pwaPlatform = 'webapp'
      // check if browser version supports the api
      if ('getInstalledRelatedApps' in window.navigator) {
        const relatedApps = await window.navigator.getInstalledRelatedApps()
        if (relatedApps.some(r => r.platform === pwaPlatform)) {
          this.isPWAInstalledAndroid = true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      forceShowAnimation: 'pwaAnimation/forceShowAnimation',
      forceShowSafariBanner: 'pwaAnimation/forceShowSafariBanner',
      forceShowAndroidAnimation: 'pwaAnimation/forceShowAndroidAnimation',
      showPWADownloadSuccessScreen: 'getShowPWADownloadSuccessScreen',
      showPWADownloadScreen: 'getShowPWADownloadScreen',
      user: 'getUser'
    }),
    computedData() {
      return {
        isRegistrationComplete: (this.showPWADownloadScreen && this.forceShowAnimation)
      }
    },
    isVerifyEmailRoute() {
      return window.location.pathname === this.$router.resolve({ name: 'verify-email' }).href
    },
    isDashboardRoute() {
      return window.location.pathname === this.$router.resolve({ name: 'dashboard' }).href
    },
    isRegisterRoute() {
      return this.$route.matched.some(({ path }) => path === '/registration')
    },
    isPWAMode() {
      return window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.navigator.standalone === true ||
        window.location.search.indexOf('utm_source=homescreen') >= 0 ||
        window.location.search.indexOf('utm_source=m4gic') >= 0
    },
    isMobile() {
      return /Android|SamsungBrowser|webOS|iPhone|iPad|Opera Mini/i.test(navigator.userAgent)
    },
    isAndroidChrome() {
      return navigator.userAgent.search(/Chrome/i) >= 0 &&
        navigator.userAgent.search(/Android/i) >= 0 &&
        navigator.userAgent.search(/SamsungBrowser/i) === -1
    },
    isForcedToShowPWADownloadScreen() {
      return this.showPWADownloadScreen && this.forceShowAnimation
    },
    isiPhoneSafari() {
      return navigator.userAgent.search(/iPhone|iPad/i) >= 0 &&
        navigator.userAgent.search(/CriOS/i) === -1
    },
    isIPhoneNonSafari() {
      return navigator.userAgent.search(/iPhone|iPad/i) >= 0 &&
        navigator.userAgent.search(/CriOS/i) >= 0
    },
    isIOSChromeOrFirefox() {
      return navigator.userAgent.search(/iPhone|iPad/i) >= 0 &&
        (navigator.userAgent.search(/CriOS/i) >= 0 || navigator.userAgent.search(/FxiOS/i) >= 0)
    },
    componentToShow() {
      switch (this.computedComponent) {
        case PWA_CHECKPOINT.REGISTER_COMPONENT:
          return registerComponent
        case PWA_CHECKPOINT.ACCOUNT_COMPONENT:
          return accountComponent
        case PWA_CHECKPOINT.ANDROID_ANIMATION:
          return androidPwaAnimation
        case PWA_CHECKPOINT.SAFARI_BANNER:
          return safariBanner
        case PWA_CHECKPOINT.PWA_DOWNLOAD_SCREEN:
          return pwaDownloadScreen
        case PWA_CHECKPOINT.PWA_DOWNLOAD_SUCCESS_SCREEN:
          return pwaDownloadSuccessScreen
        case PWA_CHECKPOINT.IOS_ANIMATION:
        default:
          return iosPwaAnimation
      }
    },
    // eslint-disable-next-line complexity
    computedComponent() {
      const registerRouteUnforcedAnimation = this.isRegisterRoute && !this.forceShowAnimation
      const verifyEmailRouteUnforcedAnimation = this.isVerifyEmailRoute && !this.forceShowAnimation
      const isPwaOrDesktop = this.isPWAMode || !this.isMobile || (!this.isAndroidChrome && !this.isiPhoneSafari)

      // Detect if not on IOS Safari and not on PWA or Desktop
      const shouldShowSafariBanner = ((this.isIOSChromeOrFirefox) || this.forceShowSafariBanner) &&
        !registerRouteUnforcedAnimation &&
        !verifyEmailRouteUnforcedAnimation

      const isIPhoneSafariNonPwa = this.isiPhoneSafari && !this.isPWAMode

      const isAccessingPWABackendViaAndroidChrome = this.isAndroidChrome &&
        !this.isPWAMode &&
        !this.isRegisterRoute &&
        !this.isVerifyEmailRoute

      const androidChromeWithPWAInstalled = this.isAndroidChrome &&
        this.isPWAInstalledAndroid &&
        !this.isPWAMode &&
        !this.isRegisterRoute &&
        !this.isVerifyEmailRoute

      const forcedAnimationPWADownloadSuccessScreen = this.forceShowAnimation && this.showPWADownloadSuccessScreen

      if (shouldShowSafariBanner) {
        return PWA_CHECKPOINT.SAFARI_BANNER
      }

      if (registerRouteUnforcedAnimation) {
        return PWA_CHECKPOINT.REGISTER_COMPONENT
      }

      if (isPwaOrDesktop || verifyEmailRouteUnforcedAnimation) {
        return PWA_CHECKPOINT.ACCOUNT_COMPONENT
      }

      if (isIPhoneSafariNonPwa && this.isDashboardRoute) {
        return PWA_CHECKPOINT.IOS_ANIMATION
      }

      if (isAccessingPWABackendViaAndroidChrome || androidChromeWithPWAInstalled || forcedAnimationPWADownloadSuccessScreen) {
        return (this.isPWAInstalledAndroid || this.forceShowAndroidAnimation)
          ? PWA_CHECKPOINT.ANDROID_ANIMATION
          : (forcedAnimationPWADownloadSuccessScreen
            ? PWA_CHECKPOINT.PWA_DOWNLOAD_SUCCESS_SCREEN
            : (this.showPWADownloadScreen || isAccessingPWABackendViaAndroidChrome
              ? PWA_CHECKPOINT.PWA_DOWNLOAD_SCREEN
              : PWA_CHECKPOINT.ANDROID_ANIMATION
            )
          )
      }

      return PWA_CHECKPOINT.ACCOUNT_COMPONENT
    }
  }
}
</script>
