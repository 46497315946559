var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "register-new" } }, [
    _c(
      "div",
      { staticClass: "wrapper", attrs: { id: "registration-complete-mobile" } },
      [
        _c("div", { staticClass: "content__area --success" }),
        _vm._v(" "),
        _c("div", { staticClass: "content__area drawer max-height-mobile" }, [
          _c("div", { staticClass: "content center-align" }, [
            _c("h2", {
              staticClass: "content__title",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$translate("register.complete_success.title")
                )
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "krypton-logo-container" }, [
              _c("img", {
                attrs: {
                  src:
                    "/img/registration/success/pwa-download-success-krypton-logo.svg"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "logo-desc" }, [
                _c("div", { staticClass: "logo-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("register.complete_success.logo_title")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logo-subtitle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("register.complete_success.logo_subtitle")
                    )
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-subtitle" }, [
              _vm._v(
                _vm._s(_vm.$translate("register.complete_success.subtitle"))
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-subtext" }, [
              _vm._v(
                _vm._s(_vm.$translate("register.complete_success.content_1"))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "actions flex-grid space-between" }, [
            _vm.FEATURE_APV_REDESIGN_A2_2825
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary btn-open-pwa",
                    attrs: {
                      href: "/",
                      target: "_blank",
                      "data-id": "open-pwa-cta"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$translate("register.complete_success.open_pwa")
                        ) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }