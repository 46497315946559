<template>
  <div id="event-declined-not-available" class="container center-align padding-x-xl padding-y-xl">
    <div v-if="step === PAGE_STEPS.NOT_AVAILABLE_1" id="event-declined-step-1">
      <h1 class="title strong margin-y-none">
        {{ $translate('events.decline_popup.not_available_2_title', { client_name: clientName }) }}
      </h1>
      <p class="margin-y-lg">
        <small>
          {{ $translate('events.decline_popup.not_available_2_note', { client_name: clientName }) }}
        </small>
      </p>
      <slot name="input" />
      <p class="margin-y-none"><strong>{{ $translate('events.decline_popup.not_comfortable_request') }}</strong></p>
      <p class="margin-y-md" style="color: #8995A3; font-size: 14px;">{{ $translate('events.decline_popup.not_comfortable_request_explainer') }}</p>
      <p id="event-declined-not-available-blacklist"
         class="white display-block inactive krypton-pink-text margin-none setting-indicator waves-dark waves-effect"
         style="font-size: 14px;"
         @click="doDeclineBlacklist">
        <i class="mdi mdi-close-circle krypton-pink-text valign-middle" style="font-size: 23px;" /> {{ $translate('events.decline_popup.not_comfortable_request_button') }}
      </p>
    </div>
    <div v-if="step === PAGE_STEPS.NOT_AVAILABLE_2" id="event-declined-step-2">
      <h1 class="title strong margin-top-none">
        {{
          $translate('events.decline_popup.not_available_1_title', {
            meeting_start_at: eventDate
          })
        }}
      </h1>
      <p>
        <small>
          {{ $translate('events.decline_popup.not_available_1_note', {
            meeting_start_at: eventDate
          }) }}
        </small>
      </p>
      <img src="/img/events/availability.png" class="availability-img responsive-img" alt="" style="min-height: 312.5px;">
    </div>
  </div>
</template>

<script>
import eventDeclinedSubViewMixin from '@/features/events/mixins/eventDeclinedSubView.mixin'

export default {
  mixins: [eventDeclinedSubViewMixin],
  methods: {
    doDeclineBlacklist () {
      this.$emit('ondeclineblacklist')
    }
  }
}
</script>

<style lang="scss" scoped>
#event-declined-not-available {
  .setting-indicator {
    padding: 4.5px 43px;
    border-radius: 4px;

    &.inactive {
      border: 2px solid #C51162;
    }
    &.active {
      border: 2px solid #00D96F;
    }
  }
}
</style>
