<template>
  <div id="profile" class="wrapper">
    <md-new-navbar />
    <div class="container">
      <div id="primary-photo">
        <div
          v-if="isPrimaryPhotoEmpty"
          class="empty-photo-placeholder"
          @click.prevent="$router.push({ name: 'photos-v2' })"
        >
          <div class="title">{{ $translate('photos.v2.add_main_photo') }}</div>
          <img
            src="/img/profile-details/add-photo-img.svg"
            alt="Add Photo"
            class="add-photo-img"
          >
          <div class="description">{{ $translate('photos.v2.add_main_photo_explanation') }}</div>
          <div class="add-photo-icon-container">
            <img
              src="/img/profile-details/add-photo-icon.svg"
              alt="Add Photo"
              class="add-photo-icon"
            >
            <div class="add-photo-icon-desc">{{ $translate('photos.v2.add_photo2') }}</div>
          </div>
        </div>
        <img v-else :src="primaryPhoto._url">
        <div class="manage-photo-container">
          <a
            id="manage-photos"
            class="valign-middle waves-effect waves-dark padding-sm floating-cta"
            @click.prevent="$router.push({ name: 'photos-v2' })"
          >
            <i class="material-icons">photo_library</i>
          </a>
        </div>
      </div>
      <div id="provider-vitals">
        <h1 id="nameAge">{{ providerNameAge }}</h1>
        <h2 id="cityName">{{ user.profiles._city_name }}</h2>
        <p
          v-if="hasMotto"
          id="profile-motto"
          :class="hasMotto ? 'enquote' : 'italic'"
          v-html="providerMotto"
        />
        <h-edit-btn
          v-if="hasMotto"
          :push="{ name: 'profile-motto' }"
          :label="mottoBtnLabel"
          :redesign="true"
        />
        <div
          v-if="!hasMotto"
          class="add-motto-cta"
          @click.prevent="$router.push({ name: 'profile-motto' })"
        >
          {{ $translate('profile.add_motto') }}
        </div>

        <div class="details-container">
          <div class="title">{{ $translate('profile.basic_information') }}</div>
          <table>
            <tr>
              <td>{{ $translate('profile.eyes') }}</td>
              <td>{{ providerEyeColor }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.hair') }}</td>
              <td>{{ providerHairColor }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.height') }}</td>
              <td>{{ providerHeight }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.weight') }}</td>
              <td>{{ providerWeight }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.bra') }}</td>
              <td>{{ providerBraCup }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.dress') }}</td>
              <td>{{ providerDress }}</td>
            </tr>
          </table>
          <h-edit-btn
            :push="{ name: 'profile-basic-details' }"
            :redesign="true"
          />
        </div>

        <div class="details-container margin-top-12">
          <div class="title">{{ $translate('profile.character') }}</div>
          <table>
            <tr>
              <td>{{ $translate('profile.languages') }}</td>
              <td>{{ providerLanguages }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.interests') }}</td>
              <td>{{ providerInterests }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.job') }}</td>
              <td>{{ providerJob }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.cuisine') }}</td>
              <td>{{ providerCuisine }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.drinks') }}</td>
              <td>{{ providerDrinks }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.smoking') }}</td>
              <td>{{ providerSmoking }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.tattoo') }}</td>
              <td>{{ providerTattoo }}</td>
            </tr>
            <tr>
              <td>{{ $translate('profile.piercing') }}</td>
              <td>{{ providerPiercing }}</td>
            </tr>
          </table>
          <h-edit-btn
            :push="{ name: 'profile-character-details' }"
            :redesign="true"
          />
        </div>

        <div id="provider-interview">
          <div class="title">{{ $translate('profile.interview_title') }}</div>
          <div v-for="(interview, idx) in providerInterview" :key="idx" class="interview">
            <div class="interview-question">
              {{ $store.getters.getDropdownInterviews(interview.title) }}
            </div>
            <div class="interview-answer">
              {{ interview.answer }}
            </div>
          </div>
          <h-edit-btn
            v-if="hasInterview"
            :redesign="true"
            :push="{ name: 'profile-interview-v2' }"
          />
          <div v-if="!hasInterview">
            <div class="placeholder">
              {{ $translate('profile.interview_placeholder') }}
            </div>
            <div
              class="add-interview-cta"
              @click.prevent="$router.push({ name: 'profile-interview-v2' })"
            >
              {{ $translate('profile.add_interview_cta') }}
            </div>
          </div>
        </div>

        <div v-if="shouldShowProviderPreferences" id="provider-preferences" class="provider-preferences">
          <div class="provider-preferences-title">{{ $translate('profile.preferences.title') }}</div>
          <div v-if="hasSelectedPreferences" class="spp-items-container">
            <div v-for="pp in selectedNsfwPreferences" class="spp-item">
              {{ pp.title }}
            </div>
          </div>
          <div v-if="!hasSelectedPreferences" class="provider-preferences-placeholder">{{ $translate('profile.preferences.description') }}</div>
          <button
            v-if="!hasSelectedPreferences"
            class="provider-preferences-cta"
            @click="showPPBottomSheet">{{ $translate('profile.preferences.cta_add_preferences') }}</button>
          <h-edit-btn
            v-if="hasSelectedPreferences"
            :redesign="true"
            @click="showPPBottomSheet"
          />
        </div>

      </div>
    </div>
    <div v-if="!showBottomStatusIndicator">
      <krypton-connect-promo-banner v-if="shouldDisplayConnectPromo" :expanded-height="true" />
      <private-place-promo-banner v-if="shouldDisplayPrivatePlacePromoBanner" :expanded-height="true" />
      <multi-location-promo-banner v-if="shouldDisplayMultiLocationPromo_PRE_A2_1815" :expanded-height="true" />
    </div>

    <provider-preferences-selection
      v-if="true"
      :is-shown="isPPBottomSheetShown"
      @submit="submitProviderPreferences"
      @close="closePPBottomSheet"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import privatePlacePromoMixin from '@/features/private-place/mixins/promo.js'
import ProviderPreferencesSelection from '@/features/provider-preferences/ProviderPreferencesSelection.vue'
import { putProviderPreferencesSettings } from '@/features/provider-preferences/services/ProviderPreferencesService.js'
import { isCandidateProfile } from '@/constants/helpers'
const LocaleProvider = $h.getProvider('LocaleProvider')

export default {
  components: {
    ProviderPreferencesSelection
  },
  mixins: [privatePlacePromoMixin],
  data: () => ({
    isPPBottomSheetShown: false
  }),
  mounted () {
    this.detectIfBottomSheetShouldShow()
    // Dispatch provider preferences
    this.dispatchFetchingOfProviderPreferences()
  },
  methods: {
    detectIfBottomSheetShouldShow () {
      if (this.$route?.params?.showPPBottomSheet && this.shouldShowProviderPreferences) {
        this.showPPBottomSheet()
      }
    },
    showPPBottomSheet () {
      this.isPPBottomSheetShown = true
    },
    dispatchFetchingOfProviderPreferences () {
      this.$store.dispatch('providerPreferences/fetch')
    },
    closePPBottomSheet () {
      // Closing pp bottom sheet
      this.isPPBottomSheetShown = false
      this.$store.commit('setUserNsfwPreferences', this.selectedNsfwPreferences)
    },
    submitProviderPreferences (selectedPreferences) {
      const payload = {
        preference_ids: selectedPreferences.map(s => s.id)
      }
      putProviderPreferencesSettings(payload).then(() => {
      // submit provider preferences
        this.$store.commit('setUserNsfwPreferences', selectedPreferences)
        this.isPPBottomSheetShown = false
      }).catch(err => {
        console.log('err submitting preferences', err)
      })
    }
  },
  computed: {
    ...mapGetters({
      showBottomStatusIndicator: 'getBottomStatusIndicator'
    }),
    shouldShowProviderPreferences () {
      return !isCandidateProfile(this.user) && Boolean(this.user?._has_5_successful_meetings) && this.FEATURE_PROVIDER_PREFERENCES
    },
    FEATURE_PROVIDER_PREFERENCES () {
      return this.$store.getters['featureflag/isEnabled']('PROVIDER_PREFERENCES')
    },
    PHOTO_FALLBACK_A2_2644 () {
      return this.$store.getters['featureflag/isEnabled']('PHOTO_FALLBACK_A2_2644')
    },
    hasSelectedPreferences () {
      return this.selectedNsfwPreferences.length > 0
    },
    isPrimaryPhotoEmpty () {
      return this.primaryPhoto?.isEmpty
    },
    user () {
      return this.$store.getters.getUser
    },
    selectedNsfwPreferences () {
      return this.user?.nsfw_preferences || []
    },
    primaryPhoto () {
      return this.$store.getters.getUserPrimaryPhoto
    },
    fallbackPhoto () {
      if (!this.PHOTO_FALLBACK_A2_2644) {
        return null
      }

      return (this.$store.getters.getUserPrimaryPhoto?._fallback_url ?? null)
        ? this.$store.getters.getUserPrimaryPhoto?._fallback_url.replace('resize', '600x800')
        : null
    },
    providerNameAge () {
      return this.user.profiles.name + ', ' + this.user.profiles.age
    },
    providerMotto () {
      return this.hasMotto ? this.user.profiles.slogan_de : this.$translate('profile.motto_placeholder')
    },
    providerEyeColor () {
      return this.$store.getters.getDropdownEyeColor(this.user.profiles.eye_color)
    },
    providerHairColor () {
      return this.$store.getters.getDropdownHairColor(this.user.profiles.hair_color)
    },
    providerHeight () {
      return this.user.profiles.height + ' cm'
    },
    providerWeight () {
      return this.user.profiles.weight + ' kg'
    },
    providerBraCup () {
      return this.$store.getters.getDropdownBraCup(this.user.profiles.bra_cup)
    },
    providerDress () {
      return 'Size ' + this.user.profiles.dress_size
    },
    providerOrigin () {
      if (!this.user.profiles.origin) {
        return ''
      }

      return this.$store.getters.getDropdownOrigin(this.user.profiles.origin)
    },
    providerLanguages () {
      var languages = []

      if (this.user.profiles.lang_1) { languages.push(this.$store.getters.getDropdownLanguage(this.user.profiles.lang_1.short)) }

      if (this.user.profiles.lang_2) { languages.push(this.$store.getters.getDropdownLanguage(this.user.profiles.lang_2.short)) }

      if (this.user.profiles.lang_3) { languages.push(this.$store.getters.getDropdownLanguage(this.user.profiles.lang_3.short)) }

      return languages.join(', ')
    },
    providerInterests () {
      if (LocaleProvider.isLocaleDe()) { return this.user.profiles.interests_de }

      return this.user.profiles.interests
    },
    providerJob () {
      return this.$store.getters.getDropdownProfession(this.user.profiles.profession)
    },
    providerCuisine () {
      if (!this.user.profiles.cuisine) { return null }

      var cuisines = $h.string2json(this.user.profiles.cuisine)

      for (var i = 0; i < cuisines.length; i++) {
        cuisines[i] = this.$store.getters.getDropdownCuisine(cuisines[i])
      }

      return cuisines.join(', ')
    },
    providerDrinks () {
      if (LocaleProvider.isLocaleDe()) { return this.user.profiles.drinks_de }

      return this.user.profiles.drinks
    },
    providerSmoking () {
      return this.$store.getters.getDropdownSmoking(this.user.profiles.smoking)
    },
    providerTattoo () {
      return this.$store.getters.getDropdownTattoo(this.user.profiles.tattoo)
    },
    providerPiercing () {
      return this.$store.getters.getDropdownPiercing(this.user.profiles.piercing)
    },
    providerInterview () {
      if (this.hasInterview) {
        if (LocaleProvider.isLocaleDe()) { return $h.string2json(this.user.profiles.interview_de) }

        return $h.string2json(this.user.profiles.interview)
      }
      return []
    },
    hasMotto () {
      return (this.user.profiles.slogan)
    },
    hasInterview () {
      if (LocaleProvider.isLocaleDe()) { return (this.user.profiles.interview_de) }

      return (this.user.profiles.interview)
    },
    interviewBtnLabel () {
      if (this.hasInterview) {
        return this.$translate('app.edit')
      }
      return this.$translate('app.add_interview')
    },
    mottoBtnLabel () {
      if (this.hasMotto) {
        return this.$translate('app.edit')
      }
      return this.$translate('app.add_motto')
    }
  }
}
</script>
